import pako from "pako";
import { ExcalidrawElement } from "../../element/types";
import { getSearchParam } from "../../utils";
import { clearLocalStorage, importFromLocalStorage } from "./localStorage";

export const uploadImageToSvc = async (file: File, imageId: string) => {
  try {
    const uploadImage = {
      operationName: "ExecSvcClient",
      variables: {
        operation: "resx.uploadDrawingServiceImage",
        args: `["${imageId}"]`,
      },
      query:
        "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
    };

    const uploadData = await fetch(process.env.REACT_APP_BACKEND_URL, {
      method: "POST",
      body: JSON.stringify(uploadImage),
      credentials: "include",
      headers: {
        // authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      keepalive: true,
    });

    const res = await uploadData.json();
    const { publicUrl, uploadUrl } = JSON.parse(
      res?.data?.execSvc?.result ?? "{}",
    );

    if (uploadUrl) {
      const uploaded = await fetch(uploadUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": !!file.type ? file.type : "application/octet-stream",
        },
      });

      if (uploaded.ok) {
        return publicUrl;
      }
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error uploading image");
  }
};

export const updateResource = async (elements: ExcalidrawElement[]) => {
  const isMemoryParam = getSearchParam("isMemory");
  const isMemory = isMemoryParam === "true";
  if (isMemory) {
    return;
  }
  const isAdminParam = getSearchParam("isAdmin");
  const isAdmin =
    typeof isAdminParam === "boolean" ? isAdminParam : isAdminParam === "true";
  const hasBackupOwnership = localStorage.getItem("backup_ownership");
  const canWrite = isAdmin || hasBackupOwnership;

  if (!canWrite) {
    return;
  }

  const resourceId = getSearchParam("resourceId");
  const roomId = getSearchParam("room");

  const smartboardState = JSON.stringify({
    elements,
    appState: {},
  });

  const headers = {
    type: "application/json",
  };
  const updateResource = {
    operationName: "ExecSvcClient",
    variables: {
      operation: "resx.updateResource",
      args: `["${resourceId}","flowos/blackboard/resx/Blackboard",{"smartboardState": ${smartboardState},"roomId": "${roomId}"}, true]`,
    },
    query:
      "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
  };

  const blob = new Blob([JSON.stringify(updateResource)], headers);

  try {
    navigator.sendBeacon(process.env.REACT_APP_BACKEND_URL, blob);
  } catch (error) {
    console.error("error", error);
  }

  clearLocalStorage();
};

export const getResource = async () => {
  const resourceId = getSearchParam("resourceId");
  const isMemoryParam = getSearchParam("isMemory");
  const isTemplateParam = getSearchParam("isTemplate");
  const resultId = getSearchParam("resultId");
  const isMemory = isMemoryParam === "true";
  const isTemplate = isTemplateParam === "true";

  const query = {
    operationName: "ExecSvcClient",
    variables: {
      operation: "resx.getSmartboard",
      args: `["${isTemplate ? resultId ?? resourceId : resourceId}", ${
        isMemory || isTemplate
      }]`,
    },
    query:
      "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
  };

  const resourceData = await fetch(process.env.REACT_APP_BACKEND_URL, {
    method: "POST",
    body: JSON.stringify(query),
    credentials: "include",
    headers: {
      // authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
    keepalive: true,
  });

  try {
    const jsonData = await resourceData.json();

    if (jsonData?.data?.execSvc?.result) {
      const resourceContent = JSON.parse(jsonData?.data?.execSvc?.result)
        ?.smartboardState?.elements;
      return resourceContent ?? null;
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveToRedis = async (elements: ExcalidrawElement[]) => {
  const isMemoryParam = getSearchParam("isMemory");
  const isSaveEnabled = getSearchParam("saveEnabled");

  const saveEnabled = isSaveEnabled === "true";
  if (!saveEnabled) {
    return;
  }

  const isMemory = isMemoryParam === "true";
  if (isMemory) {
    return;
  }

  const resourceId = getSearchParam("resourceId");
  const roomId = getSearchParam("room");

  const smartboardState = {
    elements: elements.filter((el) => !el.isDeleted),
    appState: {},
  };

  if (!elements?.length) {
    const deleteEnabled = localStorage.getItem("deleteEnabled");
    if (!deleteEnabled) {
      localStorage.removeItem("deleteEnabled");
      console.log("preventing board delete");
      return;
    }

    if (deleteEnabled) {
      const deleteEnabledAt = new Date(deleteEnabled);
      const deleteEnabledDiff = differenceInSeconds(
        new Date(),
        deleteEnabledAt,
      );
      if (deleteEnabledDiff > 10) {
        localStorage.removeItem("deleteEnabled");
        console.log("preventing delete", deleteEnabled, new Date());
        return;
      }
    }
  }

  const bodyJson = {
    smartboardState: smartboardState,
    roomId: roomId,
  };

  const body = await compressAndEncodeBase64(bodyJson);

  const jsonPayload = {
    base64data: body,
  };

  try {
    await fetch(
      `${process.env.REACT_APP_WHITEBOARD_SYNC_SERVER_URL}/api/${resourceId}`,
      {
        method: "POST",
        body: JSON.stringify(jsonPayload),
        headers: {
          "content-type": "application/json",
          "Content-Length": new TextEncoder()
            .encode(JSON.stringify(jsonPayload))
            .length.toString(),
        },
        cache: "no-cache",
        keepalive: false,
      },
    );
  } catch (error) {
    console.error("error", error);
  }
};

async function compressAndEncodeBase64(data: Object) {
  try {
    const jsonString = JSON.stringify(data);
    const utf8Data = new TextEncoder().encode(jsonString);
    const compressedData = pako.gzip(utf8Data);
    const base64StringDeprecated = btoa(
      compressedData.reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, ""),
    );

    return base64StringDeprecated;
  } catch (e) {
    console.error(e);
  }
}

function differenceInSeconds(date1: Date, date2: Date) {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.floor(diff / 1000);
}
